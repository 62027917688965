<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        <stemble-latex content="$100.0\,\text{mL}$" />
        of
        <stemble-latex content="$0.010\,\text{M}$" />
        solution of
        <chemical-latex content="H2CO3" />
        is titrated against
        <stemble-latex content="$0.010\,\text{M}\>$" />
        <chemical-latex content="NaOH" />
        solution.
      </p>
      <p class="mb-2">
        (a) Calculate the
        <stemble-latex content="$\text{pH}$" />
        at the beginning of the titration:
      </p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (b) Calculate the
        <stemble-latex content="$\text{pH}$" />
        when the volume of
        <chemical-latex content="NaOH" />
        solution added is half that needed to arrive at the first equivalence point:
      </p>
      <calculation-input
        v-model="inputs.input2"
        class="mb-5"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (c) Calculate the
        <stemble-latex content="$\text{pH}$" />
        at the first equivalence point:
      </p>
      <calculation-input
        v-model="inputs.input3"
        class="mb-5"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (d) Calculate the
        <stemble-latex content="$\text{pH}$" />
        when the volume of
        <chemical-latex content="NaOH" />
        solution added is exactly midway between the first and second equivalence points:
      </p>
      <calculation-input
        v-model="inputs.input4"
        class="mb-5"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (e) Calculate the
        <stemble-latex content="$\text{pH}$" />
        at the second equivalence point.
      </p>
      <calculation-input
        v-model="inputs.input5"
        class="mb-5"
        prepend-text="$\text{pH}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        [
        <chemical-latex content="H2CO3" />
        <stemble-latex content="$\>\text{K}_\text{a1} = 4.2\,x\,10^{-7}, $" />
        and
        <stemble-latex content="$ \text{K}_\text{a2} = 4.8\,x\,10^{-1};\>$" />
        <stemble-latex content="$\text{K}_\text{b}$" />
        <chemical-latex content="(CO3^{2-})" />
        <stemble-latex content="$\,=\, 2.1\,\text{x}\,10^{-4},\>$" />
        <stemble-latex content="$\text{K}_\text{w} $" />
        of water is
        <stemble-latex content="$ 1\,\text{x}\,10^{-14}$" />
        ]
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A6Q1',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
};
</script>
